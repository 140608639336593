import React, { useState } from 'react';
import Helmet from 'react-helmet';

import ContactoContent from '../components/organisms/contact-content/contact-content';
import Layout from '../components/organisms/layout/layout';

import favicon from '../images/favicon.ico';
import '../styles/index.scss';

const ContactoPage = () => {
  const menuActive = 3;
  const [modalActive, setmodalActive] = useState(null)

  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Layout
        menuActive={menuActive}
        footerActive={false}
        setmodalActive={setmodalActive}
        modalActive={modalActive}
        title={
          process.env.LOCALE === 'es-CO'
            ? 'Contáctanos: nos gusta escucharte - Familia Hogar'
            : process.env.LOCALE === 'es-EC'
            ? 'Contáctanos: te respondemos tus solicitudes - Familia Hogar'
            : process.env.LOCALE === 'es-DO'
            ? 'Contáctanos, queremos saber más de ti - Familia Hogar'
            : process.env.LOCALE === 'es-PR'
            ? 'Formulario de contacto, escríbenos a un clic - Familia hogar'
            : ''
        }
        slug={'/contacto/'}
      >
        <ContactoContent />
      </Layout>
    </>
  );
};

export default ContactoPage;
