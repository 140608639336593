import React from 'react';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';

import './message-modal.scss';

const MessageModal = React.forwardRef((props, ref) => {
  const { open, onClose } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div ref={ref}>
      <Dialog
        classes={{ root: 'f-message-modal' }}
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <i className="fas fa-check-circle" />
          <DialogContentText>Tu mensaje ha sido enviado</DialogContentText>
          <DialogContentText>¡exitosamente!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose} color="primary">
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default MessageModal;
