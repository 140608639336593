import { Button, Checkbox, TextField } from '@mui/material'
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { useEffect, useState, useRef } from 'react';
import useMobileMode from '../../../hooks/useMobileMode';
import SEO from '../../../shared/components/seo';
import SelectInput from '../../molecules/form/select-input';
import { siteUrl } from '../../../shared/utils/siteUrl';
import MessageModal from '../../molecules/message-modal/message-modal';
import axios from "axios"
import qs from "qs"
import {
  WebToCaseUrl,
  ORGID,
  RECORDTYPE,
} from '../../../shared/constants/api-constants';
import DataPolicy from '../../../shared/components/atoms/data-policy/data-policy';
import { Helmet } from 'react-helmet';
import * as reCaptchaKey from '../../../shared/constants/reCaptcha';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import {  INLINES } from "@contentful/rich-text-types"
import ReCAPTCHA from "react-google-recaptcha"
import './contact-content.scss';
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';

const OPTIONS_ID = ['CC','TI','DNI','CE'];
const REASONS_CONTACT = ['Queja/Reclamo', 'Felicitación', 'Solicitud de información'];
const COUNTRY_LIST = [
  'COLOMBIA',
  'ECUADOR',
  'REPÚBLICA DOMINICANA',
  'PUERTO RICO',
];

const ContactContent = () => {


  const recaptchaRef = useRef(null)
  const getRecaptcha = () => recaptchaRef.current.getValue()
  const resultQuery = useStaticQuery(
    graphql`
      query CONTACT_SEO_QUERY {
        allContentfulPagina(filter: { title: { eq: "Contacto" } }) {
          nodes {
            node_locale
            title
            metaTitle
            metaDescription {
              metaDescription
            }
            banner {
              file {
                url
              }
            }
            description {
              content {
                content {
                  value
                }
              }
            }
            checksDePoliticas {
              idCategoria
              descripcionTextoEnriquecido {
                json
              }
            }
          }
        }
      }
    `
  );
  const locale = process.env.LOCALE || "es-CO"
  const { isMobile } = useMobileMode();

  const [state, setState] = useState({
    name: '',
    nameError: false,
    phone: '',
    email: '',
    emailError: false,
    departamento: '',
    city: '',
    cityError: false,
    typeDocument: '',
    typeDocumentError: false,
    identification: '',
    identificationError: false,
    subject: '',
    subjectError: false,
    message: '',
    messageError: false,
    reasonContact: '',
    reasonContactError: false,
    country: '',
    countryError: false
  });

  const invalidEmail = 'Email no válido';
  const emptyField = 'Debes completar este campo';
  const [isOpen, setOpen] = useState(false);

  const {
    title,
    metaTitle,
    metaDescription,
    checksDePoliticas
  } = resultQuery.allContentfulPagina.nodes.filter(
    e => e.node_locale === process.env.LOCALE
  )[0];


  const onButtonClick = (e) => {
    e.preventDefault()
    const {
      name,
      email,
      phone,
      city,
      subject,
      message,
      typeDocument,
      identification,
      reasonContact,
      country,
    } = state;

    let errors = {
      name: false,
      email: false,
      city: false,
      typeDocument: false,
      identificationError: false,
      subject: false,
      message: false,
      countryState: false,
      reasonContact: false,
      country: false,
    };

    if (name === '') {
      errors.name = true;
    }
    if (email === '') {
      errors.email = true;
    }
    if (!email.includes('@')) {
      errors.email = true;
    }
    if (typeDocument === '') {
      errors.typeDocument = true;
    }
    if (identification === '') {
      errors.identification = true;
    }
    if (subject === '') {
      errors.subject = true;
    }
    if (message === '') {
      errors.message = true;
    }
    if (reasonContact === '') {
      errors.reasonContact = true;
    }
    if (country === '') {
      errors.country = true;
    }

    if (
      name !== '' &&
      email !== '' &&
      email.includes('@') &&
      typeDocument !== '' &&
      identification !== '' &&
      subject !== '' &&
      message !== '' &&
      reasonContact !== '' &&
      country !== ''
    ) {
      var ts = JSON.stringify(new Date().getTime())
      const data = {
        captcha_settings: `{"keyname":"FamilyCareV2","fallback":"true","orgId":"00D3j0000001yNQ","ts":"${ts}"}`,
        retURL: siteUrl,
        "00N3j00000GUT2O": siteUrl + '/contacto/',
        orgid: ORGID,
        recordType: RECORDTYPE,
        Origin: "Site",
        status: "GRF_PendienteTipificacion",
        GRF_Marca__c: "Familia",
        name: name,
        email: email,
        phone: phone,
        "00N3j00000GUNsa": typeDocument,
        "00N3j00000GUNs5": identification,
        "00N3j00000GUNs8": country || null,
        type: reasonContact,
        subject: subject,
        description: message,
        "g-recaptcha-response": getRecaptcha(),
      }

      axios
      .post(WebToCaseUrl, qs.stringify(data), {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          // Accept:
          //   "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
        },
      })
      .finally(() => {
        //resetForm()
        setOpen(true);
        recaptchaRef.current.execute()
      })
      datalayerSendButton(country);
    } else {
      setState({
        ...state,
        nameError: errors.name,
        emailError: errors.email,
        typeDocumentError: errors.typeDocument,
        identificationError: errors.identification,
        subjectError: errors.subject,
        messageError: errors.message,
        reasonContactError: errors.reasonContact,
        countryError: errors.country,
      });
    }
  };

  const onModalClose = () => {
    setOpen(false);
    navigate('/');
  };

  /* const timestamp = () => {
    let response = document.getElementById('g-recaptcha');
    if (response == null || response.value.trim() == '') {
      if( document.getElementsByName('captcha_settings')[0]){
        let elems =  JSON.parse(
          document.getElementsByName('captcha_settings')[0].value
        );
        elems['ts'] = JSON.stringify(new Date().getTime());
        document.getElementsByName('captcha_settings')[0].value = JSON.stringify(
          elems
        );
      }
    }
  } */

 /*  useEffect(() => {
    let captchaInterval = setInterval(timestamp, 500);
    () => {
      clearInterval(captchaInterval);
    }
  }, []) */

  useEffect(() => {
    recaptchaRef.current.execute()
  }, [recaptchaRef])
  
  useEffect(() => {
    let executionCount = true
    let divElement = document.getElementById('observedDiv')

    function getScrollPercentage() {
      let st = 'scrollTop';
      let sh = 'scrollHeight';
      let percentage = (divElement[st]) /(divElement[sh] - divElement.clientHeight);
      return Number.parseFloat(percentage).toFixed(2);
    } 

    const listenScroll = () => {
      let currentScrollY = getScrollPercentage()
      if (currentScrollY >= 0.75 && executionCount) {
        executionCount = false
        window.dataLayer = window.dataLayer || []; 
      }
    }

    divElement.addEventListener('scroll',listenScroll)

    return () => {
    divElement.removeEventListener('scroll', listenScroll)
  } 

  }, [])

  const datalayerLinks = (labelText) => {      
    let location=''
    if (typeof window !== 'undefined') {
        location= window.location.href
    }
    pushDataLayerEvent({
      event: 'Interacciones_Enlaces',
      category: 'Links',
      action: 'click',
      label: labelText,
      location: location,
      user_data: [],
      log_status: '',
    })
}

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, next) =>
        <a 
          href={node.data.uri}
          rel="noopener noreferrer"
          target={node.data.uri.includes("http") ? "_blank" : "self"}
          onClick={() => datalayerLinks(next[0].props?.children ? next[0].props.children : next[0])}
        >
          {next}
        </a>
    },
  }

  const datalayerSendButton = (country) => {      
    let location=''
    if (typeof window !== 'undefined') {
        location= window.location.href
    }
    pushDataLayerEvent({
      event: 'Interacciones_Contacto',
      category: 'Contacto',
      action: 'click',
      label: 'Enviar',
      location: location,
      country: country,
      user_data: [],
      log_status: ''
    })
  }

  useEffect(() => {
      let visibleForm = !!document.getElementById('f-contact-form');
      let nameForm = resultQuery.allContentfulPagina.nodes[0].description.content[0]
      .content[0].value;
      
      if(visibleForm){
        pushDataLayerEvent({
          event: 'form_visible',
          form_name: nameForm,
          form_id: 'f-contact-form'
        })
      }

  },[])

  return (
    <div className="f-contact-container">
      <div className="f-contact-content">
        <SEO
          title={metaTitle || title }
          description={metaDescription?.metaDescription || ''}
        />
        <Helmet>
          <meta HTTP-EQUIV="Content-type" CONTENT="text/html; charset=UTF-8" />
         {/*  <script src="https://www.google.com/recaptcha/api.js" async defer></script> */}
        </Helmet>
        <div className="f-contact-logo">
          <h1 className="f-contact-title">
            {
              resultQuery.allContentfulPagina.nodes[0].description.content[0]
                .content[0].value
            }
          </h1>
          {isMobile ? (
            <img
              src={resultQuery.allContentfulPagina.nodes[0].banner[0].file.url}
            />
          ) : (
            <img
              src={resultQuery.allContentfulPagina.nodes[0].banner[1].file.url}
            />
          )}
        </div>
        <div id='observedDiv'  className="f-contact-form-container">
          <form id="f-contact-form" className="f-contact-form" onSubmit={onButtonClick}
          >
            <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={reCaptchaKey.keyV2}
           />

            <input type="hidden" name="retURL" value={process.env.SITE_URL} />
            <input
              type="hidden"
              id="00N3j00000GUT2O"
              maxLength="255"
              name="00N3j00000GUT2O"
              size="200"
              value={
                typeof window !== 'undefined'
                  ? window.location.href
                  : process.env.SITE_URL + '/contacto'
              }
            />
            <TextField
              error={state.nameError}
              helperText={state.nameError ? emptyField : ''}
              classes={{
                root: 'f-contact-textfield',
              }}
              label= "Nombre del contacto"
              name="name"
              id="name"
              required
              onChange={e => setState({ ...state, name: e.target.value })}
              variant="standard"
            />
            <TextField
              error={state.emailError}
              helperText={
                !state.emailError
                  ? ''
                  : state.email.includes('@')
                    ? emptyField
                    : invalidEmail
              }
              classes={{
                root: 'f-contact-textfield',
              }}
              label="Email"
              name="email"
              id="email"
              required
              onChange={e => setState({ ...state, email: e.target.value })}
              variant="standard"
            />
            <TextField
              inputProps={{ maxLength: 10 }}
              classes={{
                root: 'f-contact-textfield',
              }}
              id="phone"
              label="Teléfono"
              name="phone"
              type="number"
              required
              onChange={e => setState({ ...state, phone: e.target.value })}
              variant="standard"
            />
            <SelectInput
              error={state.typeDocumentError}
              fields={OPTIONS_ID}
              label="Tipo de identificación"
              name="00N3j00000GUNsa"
              id="00N3j00000GUNsa"
              required
              onSelectChange={typeDocument =>
                setState({ ...state, typeDocument })
              }
              variant="standard"
            />
            <TextField
              error={state.identificationError}
              helperText={state.identificationError ? emptyField : ''}
              classes={{
                root: 'f-contact-textfield',
              }}
              label="Número de identificación"
              name="00N3j00000GUNs5"
              id="00N3j00000GUNs5"
              required
              onChange={e =>
                setState({ ...state, identification: e.target.value })
              }
              variant="standard"
            />
            <SelectInput
              error={state.countryError}
              fields={COUNTRY_LIST}
              label="País"
              id="00N3j00000GUNs8" 
              name="00N3j00000GUNs8" 
              title="País"
              required
              onSelectChange={country =>
                setState({ ...state, country })
              }
              variant="standard"
            />
            <SelectInput
              error={state.reasonContactError}
              fields={REASONS_CONTACT}
              label="Motivo de contacto"
              id="type" 
              name="type"
              required
              onSelectChange={reasonContact =>
                setState({ ...state, reasonContact })
              }
              variant="standard"
            />
            <TextField
              error={state.subjectError}
              helperText={state.subjectError ? emptyField : ''}
              classes={{
                root: 'f-contact-textfield',
              }}
              label="Asunto"
              name="subject"
              id="subject"
              required
              onChange={e => setState({ ...state, subject: e.target.value })}
              variant="standard"
            />
            <TextField
              error={state.messageError}
              helperText={state.messageError ? emptyField : ''}
              inputProps={{ maxLength: 500 }}
              classes={{
                root: 'f-contact-textfield',
              }}
              label="Descripción"
              name="description"
              id="description"
              multiline
              required
              rows="4"
              onChange={e => setState({ ...state, message: e.target.value })}
              variant="standard"
            />
            {checksDePoliticas.map(({ idCategoria, descripcionTextoEnriquecido }) => {
              return <div className="f-contact-checkbox">
                <Checkbox
                  required
                  color="default"
                  id={idCategoria}
                  name={idCategoria}
                  value="1"
                />
                <label className="f-contact-checkbox-description" htmlFor={idCategoria}>
                  {documentToReactComponents(descripcionTextoEnriquecido.json, options)}
                </label>
              </div>
            })}
            
            <DataPolicy/>
            <div className="f-button-send">
              <Button
                type="submit"
              >
                Enviar
              </Button>
            </div>
          </form>
        </div>
        <MessageModal open={isOpen} onClose={onModalClose} />
      </div>
    </div>
  );
};

export default ContactContent;
