import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import './select-input.scss';
export default ({ fields = [], label, required, error, onSelectChange, ...params }) => {
  const [labelName, setLabel] = React.useState('');
  const [labelWidth, setLabelWidth] = React.useState(0);
  const inputLabel = React.useRef(null);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);
  const handleChange = e => {
    setLabel(e.target.value);
    onSelectChange(e.target.value);
  };
  return (
    <FormControl
      error={error}
      required={required}
      className="f-contact-selectfield"
    >
      <InputLabel ref={inputLabel}>{label}</InputLabel>
      <Select value={labelName} onChange={handleChange} labelWidth={labelWidth} {...params}>
        <MenuItem value="" />
        {fields.map((field, index) => (
          <MenuItem key={index} value={field}>
            {field}
          </MenuItem>
        ))}
      </Select>
      {error ? (
        <FormHelperText>Debes seleccionar una opción</FormHelperText>
      ) : (
        ''
      )}
    </FormControl>
  );
};